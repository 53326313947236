<template>
  <div class="modal" :class="{ 'is-active': $parent.show_checkin_modal }">
    <div
      class="modal-background"
      @click.self="$parent.show_checkin_modal = false"
    ></div>
    <div class="modal-card" style="min-width: 55%; min-height: 60vh">
      <div class="modal-card-head p-2">
        <div class="modal-card-title is-size-5 has-text-centered">
          {{ truck_data.fields[0].value }} {{ truck_data.data.name }}
        </div>
      </div>
      <div class="modal-card-body p-0" style="width: 100%; overflow-x: hidden">
        <!-- Job details -->
        <div class="py-2 px-5">
          <p class="title is-size-6 mb-0">{{ $t("job_info_checkin") }}</p>
          <div class="is-flex is-justify-content-space-between">
            <div class="is-flex">
              <span class="mr-2">
                <small class="has-text-grey">{{
                  $t("departure_checkin")
                }}</small>
                <br />
                {{
                  $parent.$parent.selected_truck_job.details.departs_at
                    | customdate
                }}
              </span>
              <span>
                <small class="has-text-grey">{{ $t("arrival_checkin") }}</small>
                <br />
                {{
                  $parent.$parent.selected_truck_job.details.departs_at
                    | customdate
                }}
              </span>
            </div>
            <div
              v-if="
                !$parent.$parent.selected_truck_job.tx_guid &&
                !$parent.$parent.selected_truck_job.connected_project_id
              "
              class="is-align-self-flex-end"
            >
              <span>
                <!-- Add material -->
                <a
                  @click.prevent="toggle_add_material(!show_add_material)"
                  class="button is-small"
                  >{{ $t("add_material_ckeckin") }}</a
                >
              </span>
            </div>
          </div>
        </div>
        <!-- Add new material -->
        <div v-if="show_add_material == true" class="p-2">
          <div class="is-flex">
            <div class="is-flex-grow-1 ml-2" style="position: relative">
              <!-- <div v-if="search_result.length > 0" class="results_wrap_1 px-2 pt-0 custom_scrollbar-1" > -->
              <div class="results_wrap_1 pr-2 pt-0 custom_scrollbar-1">
                <button
                  @click.prevent="toggle_add_material(false)"
                  style="
                    position: absolute;
                    right: 5px;
                    top: 8px;
                    z-index: 99999;
                  "
                  class="delete is-small"
                ></button>
                <table
                  class="table is-fullwidth is-narrow is-bordered is-hoverable"
                >
                  <thead class="th-sticky has-background-white">
                    <tr>
                      <td colspan="2">
                        <div v-if="show_add_material" class="mt-1">
                          <div class="control has-icons-left has-icons-right">
                            <input
                              v-model="search_value"
                              class="input is-small"
                              type="text"
                              placeholder="Search material"
                            />
                            <span class="icon is-small is-left">
                              <i class="fas fa-search"></i>
                            </span>
                          </div>
                        </div>
                      </td>
                      <td
                        colspan="8"
                        class="has-text-centered"
                        style="color: #ccc"
                      >
                        {{ $t("dimentions") }}
                      </td>
                    </tr>
                    <tr class="grey-th">
                      <td>{{ $t("number_checkin") }}</td>
                      <td>{{ $t("material_checkin") }}</td>
                      <td>{{ $t("unit_checkin") }}</td>
                      <td>{{ $t("width_checkin") }}</td>
                      <td>{{ $t("height_checkin") }}</td>
                      <td>{{ $t("volume_checkin") }}</td>
                      <td>{{ $t("depth_checkin") }}</td>
                      <td>{{ $t("weight_checkin") }}</td>
                      <td>{{ $t("amount_checkin") }}</td>
                      <td>#</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(result, index) in search_result"
                      :key="index"
                      class="result-row has-border"
                    >
                      <td>{{ parseInt(result.Number) }}</td>
                      <td>
                        {{ result.Caption }}
                      </td>
                      <td>{{ result.Unit }}</td>
                      <td>{{ result.Width ? result.Width : "" }}</td>
                      <td>{{ result.Height ? result.Height : "" }}</td>
                      <td>{{ result.Volume ? result.Volume : "" }}</td>
                      <td>{{ result.Depth ? result.Depth : "" }}</td>
                      <td>{{ result.Weight ? result.Weight : "" }}</td>
                      <td>
                        <input
                          v-model="result.checkin_amount"
                          type="number"
                          class="input is-small"
                        />
                      </td>

                      <td class="add-mat-btn">
                        <!-- :class="{'is-hidden' : result.checkin_amount <= 0 }"> -->
                        <span>
                          <a
                            @click.prevent="select_new_material(result)"
                            href=""
                            class="button is-info is-small ml-2"
                          >
                            <i class="fas fa-plus"></i>
                          </a>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Materials table -->
        <table class="table is-fullwidth is-narrow">
          <thead>
            <tr>
              <th>{{ $t("number_checkin") }}</th>
              <th>{{ $t("material_checkin") }}</th>
              <th class="has-text-centered" width="150px">
                {{ $t("loaded_amount") }}
              </th>
              <th class="has-text-centered" width="100px">
                {{ $t("left_amount") }}
              </th>
              <th class="has-text-centered" width="160px">
                {{ $t("counted_amount") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(material, index) in $parent.$parent.selected_truck_job
                .Qty"
              :key="index"
              class="has-border"
              :class="{
                'has-background-success-light has-text-dark':
                  material.amount == 0 && material.loaded_amount == 0,
              }"
            >
              <td>{{ get_material_name(material) | rounded }}</td>
              <td>
                <small>{{ material.IdST }}</small>
                {{
                  material.Caption
                    ? material.Caption
                    : material.description
                    ? material.description
                    : ""
                }}
              </td>
              <td class="is-vertical-align-middle has-text-centered">
                {{ material.loaded_amount > 0 ? material.loaded_amount : "" }}
              </td>
              <td class="is-vertical-align-middle">
                <div class="has-text-centered">
                  <span
                    v-if="material.loaded_amount > 0"
                    class="has-text-danger-dark"
                  >
                    <i
                      v-if="
                        material.checkin_amount && count_calc(material) != 0
                      "
                      class="fa fa-exclamation-triangle mr-1 is-size-7"
                    ></i>
                    <span v-if="count_calc(material) != 0">
                      {{ count_calc(material) }}
                    </span>
                  </span>
                  <i
                    v-if="material.checkin_amount && count_calc(material) == 0"
                    class="fas fa-check has-text-success-dark"
                  ></i>
                </div>
              </td>
              <td class="has-text-centered is-vertical-align-middle">
                <div class="control">
                  <div
                    v-if="!$parent.$parent.selected_truck_job.tx_guid"
                    class="is-flex"
                  >
                    <input
                      v-model="material.checkin_amount"
                      @input="save_checkin_amount(material)"
                      class="input is-small has-text-right has-text-dark has-background-white"
                      type="number"
                      placeholder=""
                      style="max-width: 100px"
                    />
                    <span
                      v-if="material.amount == 0 && material.loaded_amount == 0"
                    >
                      <a
                        @click.prevent="remove_new_material(material)"
                        href=""
                        class="button is-danger is-small ml-2"
                      >
                        <i class="fas fa-trash"></i>
                      </a>
                    </span>
                  </div>
                  <span v-else class="has-text-grey">
                    {{ material.checkin_amount }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="modal-card-foot p-2"
        :class="{
          'has-background-success-light has-text-dark':
            $parent.$parent.selected_truck_job.tx_guid,
        }"
      >
        <a
          v-if="!$parent.$parent.selected_truck_job.tx_guid"
          @click.prevent="confirm_materials_checkin()"
          href=""
          class="button is-small is-success ml-auto"
        >
          {{ $t("confirm_checkin") }}
        </a>
        <div v-else class="notification is-success is-light ml-auto is-italic">
          <span v-if="$parent.$parent.selected_truck_job.tx_data">
            {{ $t("check_in_on") }}:
            {{
              $parent.$parent.selected_truck_job.tx_data.created_at | customdate
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      show_add_material: false,
      temp_list: [{ name: "Material 1" }, { name: "Material 2" }],
      search_value: "",
      ej_materials: [],
      new_material: {},
    };
  },
  created() {},
  methods: {
    save_checkin_amount(m) {
      // console.log('selected_truck_job', this.$parent.$parent.selected_truck_job)
      axios
        .post(this.$tetris_server + "/save/checkin/amount", {
          material: m,
        })
        .then(() => {
          this.$toast(this.$t("counted_amount_saved"), "success");
        });
    },
    toggle_add_material(show) {
      this.show_add_material = show;
      if (this.ej_materials.length == 0) {
        this.ej_materials = [...this.$parent.$parent.$parent.materials_data];
      }
      this.ej_materials.map((ej) => {
        ej.checkin_amount = 1;
      });
    },
    async bookItems(material) {
      return await axios
        .post(
          this.$ej_server + "/api.json/Items/Book",
          {
            IdJob: material.job_id,
            IdStockType: material.material_id,
            Quantity: material.amount,
          },
          { headers: this.$ej_headers }
        )
        .then((response) => {
          if (response.data.Success == true) {
            console.log("material added to job");
          }
        });
    },
    count_calc(mat) {
      if (mat.checkin_amount) return mat.loaded_amount - mat.checkin_amount;
    },
    select_new_material(mat) {
      if (mat.checkin_amount > 0) {
        // Check if material allready exist
        var mat_found = false;
        var mat_1 = {};
        this.$parent.$parent.selected_truck_job.Qty.map((mm) => {
          if (parseInt(mm.IdST) == parseInt(mat.IdST)) {
            // Add 'counted' to existing material
            // mm.checkin_amount = mm.checkin_amount + mat.checkin_amount
            // console.log('FOUND', mm, mat)
            mat_1 = mm;
            mat_found = true;
            return mm;
          }
        });
        // console.log('this.$parent.$parent.selected_truck_job.Qty', this.$parent.$parent.selected_truck_job.Qty)
        // Confirm
        this.$fire({
          text: this.$t("sure_you_add_new_mat_checkin", { mat: mat.Caption }),
          showCancelButton: true,
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
          width: 300,
          customClass: {
            confirmButton: "button is-success is-small",
            cancelButton: "button is-small",
          },
        }).then((response) => {
          if (response.value) {
            if (mat_found) {
              // If material already exist
              // edit amount request
              mat_1.checkin_amount =
                parseInt(mat_1.checkin_amount) + parseInt(mat.checkin_amount);
              this.save_checkin_amount(mat_1);
              this.toggle_add_material(false); // Close modal
              this.$toast(this.$t("material_added_success_checkin"), "success");
              // console.log('FOUND and edited', mat_1)
            } else {
              axios
                .post(this.$tetris_server + "/truck/job/add/material", {
                  material: mat,
                  truck_job: this.$parent.$parent.selected_truck_job,
                  project_id: this.$parent.$parent.$parent.currentEvent,
                })
                .then((res) => {
                  // Add Number to response
                  var res_mat = res.data[0];
                  this.$parent.$parent.$parent.materials_data.map((md) => {
                    if (md.IdStockType == res_mat.material_id) {
                      res_mat.Number = md.Number;
                      res_mat.IdST = md.IdStockType;
                    }
                  });
                  this.$parent.$parent.selected_truck_job.Qty.push(res_mat);
                  // console.log('this.$parent.$parent.selected_truck_job.Qty', this.$parent.$parent.selected_truck_job.Qty)
                  this.toggle_add_material(false); // Close modal
                  // Toast ...
                  this.$toast(
                    this.$t("material_added_success_checkin"),
                    "success"
                  );
                  // Hide add new material and remove value from amount
                  // console.log('111', this.$parent.$parent.selected_truck_job)
                });
            }
          }
        });
      } else {
        // toasted: please insert amount
        this.$toast(this.$t("specify_amount_checkin"), "danger");
      }
    },
    get_material_name(m) {
      var a = "";
      this.$parent.$parent.$parent.materials_data.map((md) => {
        if (md.IdStockType == m.IdST) a = md.Number;
      });
      return a;
    },
    getWarehouseName(id) {
      let w = {};
      let warehouse_list = JSON.parse(localStorage.warehouses);
      warehouse_list.map((e) => {
        if (e.Id == id) w = e;
      });
      return w.Caption;
    },
    async confirm_materials_checkin() {
      this.$fire({
        html: this.$parent.$parent.selected_truck_job.connected_project_id
          ? this.$t("confirm_checkin")
          : this.$t("sure_you_want_confirm_checkin"),
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        width: 500,
        customClass: {
          confirmButton: "button is-success is-small",
          cancelButton: "button is-small",
          ..."swal-wide",
        },
      }).then((response) => {
        if (response.value) {
          if (this.$parent.$parent.selected_truck_job.connected_project_id) {
            axios
              .post(this.$tetris_server + "/transit/materials/checkin", {
                selected_truck_job: this.$parent.$parent.selected_truck_job,
                user: JSON.parse(localStorage.user),
                project: this.$parent.$parent.$parent.event_details.IdProject,
              })
              .then((r) => {
                //update tx guid in the array
                // this.$parent.$parent.selected_truck_job.tx_guid = r.data.guid
                console.log(r);
              });
            this.$parent.show_checkin_modal = false;
            console.log(
              "selected truck job",
              this.$parent.$parent.selected_truck_job
            );
          } else
            axios
              .post(this.$tetris_server + "/confirm/materials/ckeckin", {
                selected_truck_job: this.$parent.$parent.selected_truck_job,
                user: JSON.parse(localStorage.user),
                project: this.$parent.$parent.$parent.event_details.IdProject,
              })
              .then((res) => {
                //you can only do the checkin once. if the guid is assigned you can not do checkin again
                this.$parent.$parent.selected_truck_job.tx_guid =
                  res.data.tx[0].guid;

                // this.$parent.$parent.selected_truck_job.tx_data = res.data.tx[0]
                // this.$parent.$parent.selected_truck_job.tx_guid = res.data.tx[0].guid
                // this.$parent.$parent.selected_truck_job = {...this.$parent.$parent.selected_truck_job}

                // console.log('this.$parent.$parent.selected_truck_job', this.$parent.$parent.selected_truck_job)
                this.$toast(this.$t("materials_checkin_success"), "success");
                // {
                // "do_checkin":0,
                // "checkins": [
                // 	{"ej_id":3949,"guid":"1b3a21d8-7f33-4a36-8272-724eed61927d","checkin_amount":51,"undo_amount":0},
                // 	{"ej_id":3950,"guid":"f8dd124a-e04f-423d-ac86-0ea74ca42297","checkin_amount":10,"undo_amount":0}],
                // "checkouts":[],
                // "material_not_checked_out":[{"material_id":194,"amount_not_found":1}]}

                this.$fire({
                  html:
                    "<b>Checkin materials in easyjob?</b><br><br>" +
                    // "Do Checkin Config: <b>" + (res.data.do_checkin == 0 ? 'NO' : 'YES') + "</b><br>" +
                    "Checkout job materials that were returned and need to be checkedin: <b>" +
                    res.data.checkins.length +
                    "</b><br>" +
                    // "RAW: <code>" + JSON.stringify(res.data.checkins) +"</code><br>" +

                    "Materials that the checkin was postponed: <b>" +
                    res.data.checkouts.length +
                    "</b><br>" +
                    // "RAW: <code>" + JSON.stringify(res.data.checkouts) +"</code><br>" +
                    "Materials that were never checked out but present in the loading: <b>" +
                    res.data.material_not_checked_out.length +
                    "</b><br>",
                  //   +
                  // "RAW: <code>" +
                  // JSON.stringify(res.data.material_not_checked_out) +
                  // "</code>"
                  confirmButtonText: this.$t("yes"),
                  width: 650,
                  customClass: {
                    confirmButton: "button is-success is-small",
                  },
                }).then(async () => {
                  // process here in the easyjob instructions
                  var checkinresponse = res.data;

                  let transfer_data = [];
                  if (checkinresponse.do_checkin == 0) {
                    // This will not do the checkin, instead it will just let the amount out if was not returned,
                    // otherwise it will just undo the checkouts of that material
                    for (const el of checkinresponse.checkins) {
                      // let el = res.data.checkins[i];
                      let checkouts = res.data.checkouts;

                      if (el.transfer) {
                        //search in transfer_data for origin. if found, add the amount to the material_id if not found, add a new object to the array
                        let found = transfer_data.find(
                          (x) => x.origin == el.origin
                        );

                        if (found) {
                          let found_material = found.material.find(
                            (a) => a.material_id == el.IdST
                          );

                          if (found_material) {
                            found_material.amount += el.checkin_amount;
                          } else {
                            found.material.push({
                              material_id: el.IdST,
                              amount: el.checkin_amount,
                            });
                          }
                        } else {
                          transfer_data.push({
                            ej_id: el.ej_id,
                            origin: el.origin,
                            transfer: el.transfer,
                            material: [
                              {
                                material_id: el.IdST,
                                amount: el.checkin_amount,
                              },
                            ],
                          });
                        }
                      }

                      await axios
                        .post(
                          this.$ej_server +
                            "/api.json/custom/jobundocheckout/" +
                            el.ej_id,
                          {},
                          { headers: this.$ej_headers }
                        )
                        .then(async (ju) => {
                          let remains_out = true;
                          console.log(checkouts, "checkouts");

                          console.log(
                            "did the undo ",
                            ju.data,
                            "remains out",
                            remains_out
                          );
                          if (
                            el.undo_amount == el.checkin_amount &&
                            remains_out == false
                          ) {
                            // then you can delete the job
                            // if(ju.data.Success == true){
                            // if single material per job
                            // axios
                            //   .post(
                            //     this.$ej_server +
                            //       "/api.json/jobs/delete/" +
                            //       el.ej_id,
                            //     {},
                            //     { headers: this.$ej_headers }
                            //   )
                            //   .then((deleletejobresp) => {
                            //     if (deleletejobresp.status == 200) {
                            //       console.log(
                            //         el.ej_id +
                            //           " job and material were checkedin successfully"
                            //       );
                            //     }
                            //   });
                            // }
                          } else {
                            // book the material undo amount
                            var post_data = {
                              IdJob: el.ej_id,
                              IdStockType: el.IdST,
                              Quantity: 0 - el.checkin_amount,
                            };

                            await axios
                              .post(
                                this.$ej_server + "/api.json/Items/Book",
                                post_data,
                                { headers: this.$ej_headers }
                              )
                              .then(async (e) => {
                                console.log("rechecked out " + e.data);
                                console.log("2nd call");

                                // here also the daytimeout should be checked if the job has an overdue as easyjob
                                // won't save a job in the past

                                await axios
                                  .get(
                                    this.$ej_server +
                                      "/api.json/Items/BillOfItems/" +
                                      el.ej_id,

                                    { headers: this.$ej_headers }
                                  )
                                  .then(async (ra) => {
                                    console.log("3d call");

                                    if (
                                      ra.data.length == 1 &&
                                      ra.data[0].Qty == null
                                    ) {
                                      console.log(
                                        "to be deleted, but not if it's a transfer"
                                      );
                                      //delete if not a transfer job
                                      if (!el.transfer) {
                                        console.log("3e call");

                                        axios
                                          .post(
                                            this.$ej_server +
                                              "/api.json/jobs/delete/" +
                                              el.ej_id,
                                            {},
                                            { headers: this.$ej_headers }
                                          )
                                          .then((deleletejobresp) => {
                                            if (
                                              deleletejobresp.status == 200 &&
                                              deleletejobresp.data.Success ==
                                                true
                                            ) {
                                              console.log(
                                                el.ej_id +
                                                  " deleted successfully, it was not a transfer job"
                                              );
                                            }
                                          });
                                      }
                                    } else {
                                      console.log("3b call");

                                      await axios
                                        .post(
                                          this.$ej_server +
                                            "/api.json/custom/jobcheckout/" +
                                            el.ej_id,
                                          {},
                                          { headers: this.$ej_headers }
                                        )
                                        .then((ea) => {
                                          console.log("3b1 call");

                                          console.log(
                                            "aaaa",
                                            ea.data.Success,
                                            ea
                                          );
                                        });
                                    }
                                    //if el.transfer

                                    console.log(transfer_data, "transfer_data");
                                  });
                                //   });
                              });
                          }
                        });
                    }

                    let new_jobs = [];

                    var i = 1;
                    for (const td of transfer_data) {
                      new_jobs.push(
                        setTimeout(() => {
                          this.saveJobInEj(td);
                        }, i * 300)
                      );
                      i = i + 1;
                    }

                    await Promise.all(new_jobs).catch((err) => {
                      console.log(err);
                    });

                    // TODO:
                    // This will do the checkin and create other jobs for the materials that still remain out
                  }
                  //close the modal

                  this.$parent.show_checkin_modal = false;
                  // this.$parent.revaluateSelectedWarehouseJobs();
                });
              });
        }
      });
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    getDayTime(when) {
      var d = new Date();
      d.setDate(d.getDate() + when);

      var month = d.getMonth() + 1;

      // var d2 = new Date(new Date().getTime()+(5*24*60*60*1000));
      console.log(when);
      var day = d.getDate();
      var output =
        d.getFullYear() +
        "-" +
        (month < 10 ? "0" : "") +
        month +
        "-" +
        (day < 10 ? "0" : "") +
        day;

      let hours = this.addZero(d.getHours());
      let minutes = this.addZero(d.getMinutes());
      output = output + " " + hours + ":" + minutes;
      return output;
    },

    async saveJobInEj(dat) {
      let origin_wh = this.getWarehouseName(dat.origin);
      let transfer_wh = this.getWarehouseName(dat.transfer);
      return await axios
        .post(
          this.$ej_server + "/api.json/jobs/save",
          {
            Caption: "Transfer " + origin_wh + " to " + transfer_wh,
            Address_Delivery: null,
            Contact_Delivery: null,
            DayTimeOut: this.getDayTime(1),
            DayTimeIn: this.getDayTime(2),
            IdJob: null,
            JobState: {
              ID: 1, // Must be confirmed job!
              IdJobState: 1, // Must be confirmed job!
            },
            Project: {
              ID: this.$parent.$parent.$parent.event_details.IdProject,
              IdProject: this.$parent.$parent.$parent.event_details.IdProject,
            },
            Stock: {
              ID: dat.origin,
              IdStock: dat.origin,
            },
          },
          { headers: this.$ej_headers }
        )
        .then(async (res) => {
          let new_job_id = res.data.ID;

          //we do the transfer in easyJob

          await axios
            .post(
              this.$ej_server + "/api.json/custom/SetReturnSite/",
              {
                IdStock: dat.transfer,
                IdJob: new_job_id,
              },
              { headers: this.$ej_headers }
            )
            .then(async () => {
              //we do the checkout in easyJob

              let new_jobmaterials = [];
              for (const m of dat.material) {
                m.job_id = new_job_id;
                new_jobmaterials.push(
                  setTimeout(() => {
                    this.bookItems(m), 1000;
                  })
                );
              }
              await Promise.all(new_jobmaterials).then(async () => {
                // console.log("added the materials");

                //we do the checkout

                await axios
                  .post(
                    this.$ej_server +
                      "/api.json/custom/jobcheckout/" +
                      new_job_id,
                    {},
                    { headers: this.$ej_headers }
                  )
                  .then(async () => {
                    // console.log("checkout from new one", ea);

                    await axios
                      .post(
                        this.$ej_server +
                          "/api.json/custom/jobcheckin/" +
                          new_job_id,
                        {},
                        { headers: this.$ej_headers }
                      )
                      .then(() => {
                        // console.log("checkin from new one", aa);
                      });
                  });
              });
            });
        });
    },
    material_amount_remains_out(checkouts, material) {
      let remains_out = false;
      checkouts.map((c) => {
        // if(c.ej_id == material.ej_id && c.IdST == material.IdST)
        if (c.guid == material.guid) remains_out = true;
      });
      return remains_out;
    },
    remove_new_material(mat) {
      this.$fire({
        text: this.$t("remove_new_material_confirm", { m: mat.description }),
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        width: 300,
        customClass: {
          confirmButton: "button is-success is-small",
          cancelButton: "button is-small",
        },
      }).then((response) => {
        if (response.value) {
          axios
            .post(this.$tetris_server + "/remove/new/material", {
              material: mat,
            })
            .then((res) => {
              // console.log('res...', res.data)
              this.$parent.$parent.selected_truck_job.Qty =
                this.$parent.$parent.selected_truck_job.Qty.filter(
                  (m) => m.guid != res.data[1].guid
                );
              this.$toast(this.$t("material_removed_successfully"), "info");
            });
        }
      });
    },
  },
  computed: {
    truck_data() {
      return this.$parent.getTruckData(
        this.$parent.$parent.selected_truck_job.details.truck_id,
        this.$parent.$parent.warehouses_parameters
      );
    },
    search_result() {
      return this.ej_materials
        .filter((tl) => {
          if (this.search_value.length > 1) {
            tl.checkin_amount = 1;
            return tl.Caption.toLowerCase().includes(
              this.search_value.toLowerCase()
            );
          } else return false;
        })
        .filter((ej_mat) => {
          var found = false;
          for (
            var x = 0;
            x < this.$parent.$parent.selected_truck_job.Qty.length;
            x++
          ) {
            if (
              ej_mat.IdST == this.$parent.$parent.selected_truck_job.Qty[x].IdST
            ) {
              // console.log('...', ej_mat.IdST, this.$parent.$parent.selected_truck_job.Qty[x].IdST)
              found = true;
            }
          }
          return !found;
        })
        .sort(function (a, b) {
          return a.Caption > b.Caption ? 1 : -1;
        })
        .slice(0, 10);
    },
  },
};
</script>

<style scoped>
.results_wrap {
  position: absolute;
  /* background:#fff; */
  max-height: 300px;
  overflow-y: scroll;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.results_wrap_1 {
  width: 99%;
  right: 0px;
  max-height: 300px;
  overflow-y: scroll;
  position: absolute;
  z-index: 999;
  background: rgb(255, 255, 255);
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.single-result {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.results-details > span {
  padding: 3px 6px;
  border-right: solid 1px rgba(97, 92, 80, 0.205);
}
.result-row .add-mat-btn > span {
  /* display:none; */
  opacity: 50%;
}
.result-row:hover .add-mat-btn > span {
  opacity: 100%;
  /* display:block; */
}
.grey-th td {
  color: #4d5859;
}
pre {
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.swal-wide {
  width: 850px !important;
}
</style>
